import { getEnv } from "./utils";

const USER = "user";
const SECOND = 1000;
const MINUTE = SECOND * 60;
const TOKEN_TTL = MINUTE * 19;
const IDLE_TIMEOUT_MIN = MINUTE * 10;
const PASSWORD_STRENGTH = {
	MIN_LENGTH: 8,
	MIN_UPPERCASE: 1,
	MIN_LOWERCASE: 1,
	MIN_SPECIALCHARACTER: 1,
	MIN_NUMERIC: 1,
};
const MFA_STRENGTH = {
	MIN_LENGTH: 6,
	MIN_NUMERIC: 6,
};
const EMAIL_PLACEHOLDER = "Enter an email address";
const VERIFICATION_CODE_PLACEHOLDER = "123456";
const PASSWORD_PLACEHOLDER = "********";
const OTP_PLACEHOLDER = "123456";
const FIRSTNAME_PLACEHOLDER = "Enter first name";
const LASTNAME_PLACEHOLDER = "Enter last name";
const PHONE_PLACEHOLDER = "Enter phone number";

const ENV_MAPPER = {
	development: "dev",
	staging: "staging",
	test: "test",
	qa: "qa",
	uat: "uat",
	production: "",
	local: "local",
	integration: "intg",
};

const AUTH_TOKEN =
	getEnv() !== ENV_MAPPER.production ? "authToken" : "ssoToken";
const APP_PERMISSION_MAPPER: any = {
	account: "app.account.view",
	workbench: "app.workbench.view",
	admin: "nf.app.platform.view",
	inventory: "app.inventory.view",
	userEdit: "users.self.manage",
	userView: "users.self.view",
	notificationView: "users.self.notifications.manage",
	notificationAction: "notifications.manage",
};

const USER_PERMISSIONS_MAPPER: any = {
	"travel.passengers.manage": "Travel: Add and manage passenger profiles",
	"travel.passengers.view": "Travel: Search and use passenger profiles",
	"travel.orders.manage": "Travel: Create and manage all  travel orders",
	"travel.orders.view": "Travel: Search and view all travel orders",
	"reports.sales.view": "Reports: View sales reports",
	"nf.content.services.view": "Content: View configuration of Content Services",
	"nf.airlines.manage": "Airlines: Add and manage Airline profiles",
	"nf.distributors.manage":
		"Distributor: Add and manage Content Distributor profiles",
	"nf.agencies.manage": "Travel Agencies: Add and manage agency accounts",
	"agencies.view": "Travel Agencies: View agency account profile",
	"nf.agencies.subscriptions.manage":
		"Travel Agencies: Add and manage content subscriptions",
	"agencies.subscriptions.view":
		"Travel Agencies: View status and detail of content subscriptions",
	"travel.transactions.manage":
		"Transactions: Manage transaction sync status (publish/export)",
	"travel.transactions.view": "Transactions: Search and view transactions",
	"travel.transactions.setup.manage":
		"Transactions: Add and manage transaction setup (publish/export)",
	"notifications.manage":
		"Notifications: View and manage notification assignments",
	"users.manage": "Users: Add and manage user profiles",
	"users.view": "Users: View users and user profiles",
	"users.self.manage": "Users: Manage own profile",
	"users.self.view": "Users: View own profile",
	"users.self.notifications.manage": "Users: Manage own notifications",
	"nf.app.platform.view":
		"Application: Allow access to Platform Administration",
	"app.workbench.view": "Application: Allow access to Travel Workbench",
	"app.account.view": "Application: Allow access to Account Administration",
	"travel.self.orders.booked.manage":
		"Travel: Create and manage own Booked travel orders",
	"travel.self.orders.view": "Travel: Search and view own travel orders",
	"travel.self.orders.paid.manage":
		"Travel: Create and manage own Ticketed travel orders",
	"travel.orders.create": "Travel : Create all travel orders",
	"travel.orders.issueTicket": "Travel : Issue Ticket for all travel orders",
	"travel.orders.cancelTickets": "Travel : Cancel Ticket for all travel orders",
	"agencies.profile.manage": "Add and Manage agency Profile",
	"app.inventory.view": "Application: Allow access to Local Inventory",
	"inventory.trip.manage": "Inventory: Create and manage inventory Item",
	"inventory.trip.view": "Inventory: Search and View inventory items",
	"providers.payments.manage":
		"Supplier Payments: Maintain setup, manage FOP accounts/cards",
	"agencies.iata.manage":
		"Travel Agencies: Add and manage agency account IATA profiles only (Add/Edit/Delete)",
	"agencies.manage": "Travel Agencies: Add and manage agency accounts",
	"agencies.subscriptions.manage":
		"Travel Agencies: Add and manage content subscriptions",
	"users.txns.manage": "Users: Download user transaction traces",
	"travel.subagency.orders.manage": "View and manage sub agency created orders",
};

const REFRESH_TOKEN = "refreshToken";

export {
	USER,
	AUTH_TOKEN,
	TOKEN_TTL,
	IDLE_TIMEOUT_MIN,
	PASSWORD_STRENGTH,
	EMAIL_PLACEHOLDER,
	VERIFICATION_CODE_PLACEHOLDER,
	PASSWORD_PLACEHOLDER,
	FIRSTNAME_PLACEHOLDER,
	LASTNAME_PLACEHOLDER,
	PHONE_PLACEHOLDER,
	ENV_MAPPER,
	APP_PERMISSION_MAPPER,
	USER_PERMISSIONS_MAPPER,
	REFRESH_TOKEN,
	OTP_PLACEHOLDER,
	MFA_STRENGTH,
};
